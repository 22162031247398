import WorkspaceAPIs from "@/api/services/workspace.api";
import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { IWorkspace } from "./workspace.slice";

export interface IWorkspaces {
  workspaces: IWorkspace[];
}

const initialState: IWorkspaces = {
  workspaces: [],
};

export type WorkspacesSliceStateType = Pick<RootState, "workspaces">;

export const requestWorkspaces = createAsyncThunk(
  "[workspacesSlice]: requestWorkspaces",
  async () => {
    const { data, status } = await WorkspaceAPIs.RequestWorkspaces();
    if (status === 401) {
      isRejectedWithValue({ data, status });
    }
    return data;
  },
);

export const workspacesSlice = createSlice({
  name: "[workspacesSlice]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestWorkspaces.fulfilled, (draftState, action) => {
      draftState.workspaces = action.payload.data.workspaces;
    });
  },
});

export const selectWorkspaces = (state: WorkspacesSliceStateType) =>
  state.workspaces.workspaces;

export const {} = workspacesSlice.actions;

export default workspacesSlice.reducer;
